import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="bg-white/90 backdrop-blur-sm shadow-lg p-4 rounded-lg border border-gray-200">
        <p className="font-semibold text-gray-900 mb-1">{data.label}</p>
        <p className="text-sm text-gray-600">
          {data.formattedValue} ({data.percentage}%)
        </p>
      </div>
    );
  }
  return null;
};

const BudgetDistribution = ({ categories }) => {
  const parseBudget = (budgetStr) => {
    if (!budgetStr) return 0;
    const num = parseFloat(budgetStr.replace(/[^0-9.]/g, ''));
    if (budgetStr.includes('K')) return num * 1000;
    if (budgetStr.includes('M')) return num * 1000000;
    return num;
  };

  const categoryBudgets = Object.values(categories).map(category => {
    let budget = 0;
    if (category.metrics.budget) {
      budget = parseBudget(category.metrics.budget);
    } else if (category.metrics.totalSecured) {
      budget = parseBudget(category.metrics.totalSecured);
    }
    return {
      label: category.label,
      value: budget,
      color: category.color
    };
  });

  const totalBudget = categoryBudgets.reduce((sum, cat) => sum + cat.value, 0);

  const data = categoryBudgets.map(cat => ({
    ...cat,
    percentage: ((cat.value / totalBudget) * 100).toFixed(1),
    formattedValue: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      compactDisplay: 'short'
    }).format(cat.value)
  }));

  return (
    <div className="w-full bg-white/90 backdrop-blur-sm shadow-lg rounded-xl p-6">
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Budget Distribution</h2>
        <p className="text-sm text-gray-600">Allocation across different categories</p>
      </div>
      
      <div className="h-[300px] relative">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="label"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={2}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-6">
        {data.map((category, index) => (
          <div key={index} className="flex items-center gap-2">
            <div 
              className="w-3 h-3 rounded"
              style={{ backgroundColor: category.color }}
            />
            <span className="text-sm text-gray-600">
              {category.label} ({category.percentage}%)
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BudgetDistribution;
