import React, { useState } from 'react';
import { FaTimes, FaInfoCircle } from 'react-icons/fa';

const AlertPopUp = ({ 
  message = "System maintenance in progress. Some features may be temporarily unavailable.",
  severity = "warning",
  persistent = false,
  onClose
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  if (!open) return null;

  const severityClasses = {
    error: 'bg-red-100 text-red-800 border-red-300',
    warning: 'bg-yellow-100 text-yellow-800 border-yellow-300',
    info: 'bg-blue-100 text-blue-800 border-blue-300',
    success: 'bg-green-100 text-green-800 border-green-300'
  };

  return (
    <div className="w-full">
      <div className={`flex items-center p-4 rounded-lg border ${severityClasses[severity] || severityClasses.warning}`}>
        <FaInfoCircle className="flex-shrink-0 w-5 h-5 mr-3" />
        <div className="flex-grow">
          {typeof message === 'string' ? message : message}
        </div>
        {!persistent && (
          <button
            onClick={handleClose}
            className="flex-shrink-0 ml-3 p-1.5 rounded-full hover:bg-opacity-20 hover:bg-black transition-colors duration-200"
            aria-label="close"
          >
            <FaTimes className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
};

export default AlertPopUp;
