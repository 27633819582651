import React from 'react';

const formatCurrency = (value) => {
  return `$${Math.abs(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

const TableCell = ({ children, isFirstColumn, isNumeric, isHeader, isSubtotal, isFooter, isNegative }) => {
  const baseClasses = "py-2 px-4 text-sm";
  const colorClasses = isNegative ? "text-red-600" : "text-gray-700";
  const fontClasses = isNumeric ? "font-mono" : "font-sans";
  const alignClasses = isNumeric ? "text-right pr-4" : "text-left";
  const weightClasses = (isSubtotal || isHeader || isFooter) ? "font-semibold" : "font-normal";
  const firstColumnClasses = isFirstColumn
    ? `sticky left-0 z-10 pl-6 ${
        isHeader || isFooter 
          ? "bg-gray-50" 
          : isSubtotal 
            ? "bg-gray-50"
            : "bg-white"
      }`
    : "";

  return (
    <div className={`${baseClasses} ${colorClasses} ${fontClasses} ${alignClasses} ${weightClasses} ${firstColumnClasses}`}>
      {children}
    </div>
  );
};

const TableRow = ({ children, isHeader, isSubtotal, isFooter }) => {
  const baseClasses = "grid grid-cols-[200px_repeat(10,minmax(120px,1fr))] min-w-[1400px] items-center border-b border-gray-200 transition-all duration-200";
  const variantClasses = isHeader || isFooter
    ? "bg-gray-50 rounded-lg sticky left-0 font-semibold"
    : isSubtotal
      ? "bg-gray-50"
      : "hover:bg-gray-50 hover:translate-x-1";

  return (
    <div className={`${baseClasses} ${variantClasses}`}>
      {children}
    </div>
  );
};

const SummaryTable = ({ data }) => {
  const years = Array.from({ length: 10 }, (_, i) => 2024 + i);
  
  return (
    <div className="my-8 bg-white rounded-xl p-6 shadow-sm overflow-hidden">
      <h2 className="text-xl font-semibold text-gray-900 mb-6 px-6">Summary</h2>
      
      <div className="overflow-x-auto -mx-6 px-6 scrollbar-hide">
        <TableRow isHeader>
          <TableCell isFirstColumn isHeader>Category</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric isHeader>{year}</TableCell>
          ))}
        </TableRow>

        {/* Harvesting Section */}
        <TableRow>
          <TableCell isFirstColumn>Tonnes SW</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric>9,754</TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell isFirstColumn>Tonnes HW</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric>12,835</TableCell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <TableCell isFirstColumn isSubtotal>Tonnes Total</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric isSubtotal>22,588</TableCell>
          ))}
        </TableRow>

        {/* Stumpage Section */}
        <TableRow>
          <TableCell isFirstColumn>Av. Stumpage SW</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric>{formatCurrency(26.22)}</TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell isFirstColumn>Av. Stumpage HW</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric>{formatCurrency(9.27)}</TableCell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <TableCell isFirstColumn isSubtotal>Av. Stumpage</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric isSubtotal>{formatCurrency(16.59)}</TableCell>
          ))}
        </TableRow>

        {/* Sales Section */}
        <TableRow>
          <TableCell isFirstColumn>Stumpage Sales SW</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric>{formatCurrency(255732.08)}</TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell isFirstColumn>Stumpage Sales HW</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric>{formatCurrency(118942.59)}</TableCell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <TableCell isFirstColumn isSubtotal>Stumpage Sales Total</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric isSubtotal>{formatCurrency(374674.67)}</TableCell>
          ))}
        </TableRow>

        {/* Funding Section */}
        <TableRow isSubtotal>
          <TableCell isFirstColumn isSubtotal>Funding</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric isSubtotal>{formatCurrency(488546.90)}</TableCell>
          ))}
        </TableRow>

        {/* Costs Section */}
        <TableRow isSubtotal>
          <TableCell isFirstColumn isSubtotal>Road Construction</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric isSubtotal>{formatCurrency(0)}</TableCell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <TableCell isFirstColumn isSubtotal>Silviculture</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric isSubtotal>{formatCurrency(488546.90)}</TableCell>
          ))}
        </TableRow>
        <TableRow isSubtotal>
          <TableCell isFirstColumn isSubtotal>Costs Total</TableCell>
          {years.map(year => (
            <TableCell key={year} isNumeric isNegative isSubtotal>{formatCurrency(119192.00)}</TableCell>
          ))}
        </TableRow>
      </div>
    </div>
  );
};

export default SummaryTable;
