import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';

const Timeline = ({ years, categories }) => {
  const [openYears, setOpenYears] = useState({});

  const toggleYear = (year) => {
    setOpenYears(prev => ({
      ...prev,
      [year]: !prev[year]
    }));
  };

  const getTimelineData = (category, year) => {
    switch (category.label) {
      case 'Harvesting':
        return category.metrics.operations.map(op => ({
          label: op.name,
          value: op.volume,
          status: op.status,
          completion: op.completion
        }));
      case 'Funding':
        return category.metrics.grants.map(grant => ({
          label: grant.name,
          value: grant.amount,
          status: grant.status
        }));
      case 'Silviculture':
        return category.metrics.treatments.map(treatment => ({
          label: treatment.type,
          value: treatment.area,
          status: treatment.status
        }));
      default:
        return [];
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'text-green-600 bg-green-100';
      case 'in progress':
        return 'text-blue-600 bg-blue-100';
      case 'planned':
        return 'text-yellow-600 bg-yellow-100';
      case 'delayed':
        return 'text-red-600 bg-red-100';
      default:
        return 'text-gray-600 bg-gray-100';
    }
  };

  return (
    <div className="bg-white/90 backdrop-blur-sm shadow-lg p-8 rounded-xl mt-8">
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-1">Timeline</h2>
        <p className="text-sm text-gray-600">Detailed breakdown of activities by year</p>
      </div>

      <div className="relative pt-4">
        <div className="absolute left-0 w-0.5 h-full bg-gradient-to-b from-green-500 to-blue-500" />

        {years.map((year, yearIndex) => (
          <div key={year} className="ml-8 mb-12 last:mb-0 relative">
            <div 
              className="absolute left-[-2.5rem] w-4 h-4 rounded-full bg-green-500 border-3 border-white"
              style={{ top: '2px' }}
            />

            <div 
              className="flex items-center gap-4 cursor-pointer hover:opacity-80"
              onClick={() => toggleYear(year)}
            >
              <FaChevronRight 
                className={`transition-transform duration-200 ${openYears[year] ? 'rotate-90' : ''}`}
              />
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{year}</h3>
            </div>

            {openYears[year] && (
              <div className="overflow-x-auto -mx-8 px-8 scrollbar-hide">
                <div className="grid gap-4 min-w-[768px]">
                  {Object.entries(categories).map(([key, category]) => {
                    const timelineData = getTimelineData(category, year);
                    if (!timelineData.length) return null;

                    return (
                      <div key={key} className="bg-gray-50 rounded-lg p-4">
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center gap-2 font-semibold text-gray-900">
                            <div 
                              className="w-3 h-3 rounded"
                              style={{ backgroundColor: category.color }}
                            />
                            {category.label}
                          </div>
                          <span className={`px-3 py-1 rounded-full text-xs ${getStatusColor(category.metrics.status)}`}>
                            {category.metrics.status || 'No Status'}
                          </span>
                        </div>

                        <div className="grid gap-2">
                          {timelineData.map((item, index) => (
                            <div key={index} className="flex justify-between items-start p-2 bg-white/80 rounded">
                              <span className="text-sm text-gray-700">{item.label}</span>
                              <div className="flex items-center gap-2 flex-wrap">
                                {item.value && (
                                  <span className="text-sm text-gray-600">{item.value}</span>
                                )}
                                {item.status && (
                                  <span className={`px-3 py-1 rounded-full text-xs ${getStatusColor(item.status)}`}>
                                    {item.status}
                                  </span>
                                )}
                                {item.completion && (
                                  <span className="text-sm text-gray-600">{item.completion}%</span>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
