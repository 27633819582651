import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../../styles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Cell, LabelList } from 'recharts';
import { fetchAndParseAirtableRecords } from '../../../services/airtableService';
import LoadingOverlay from '../../../components/BaseComponents/LoadingOverlay';
// Common styled components
const DashboardContainer = styled.div`
  padding: ${theme.common.spacing.lg};
  padding-top: ${theme.common.timeline.container.topSpacing};
  background: transparent;
  max-width: 1300px;
  margin: 0 auto;
`;

const Header = styled.div`
  margin-bottom: ${theme.common.spacing.xl};
  padding-bottom: ${theme.common.spacing.md};
  border-bottom: 1px solid ${theme.colors.border.light};
`;

const Title = styled.h1`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.sm};
  font-size: 1.8rem;
`;

const GrossProfit = styled.div`
  color: ${props => props.negative ? 'var(--forest-loss)' : 'var(--forest-profit)'};
  font-size: 2rem;
  font-weight: bold;
  font-family: ${theme.fonts.body};
`;

const Overview = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 8px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
`;

const SectionTitle = styled.h2`
  font-family: ${theme.fonts.heading};
  color: ${theme.colors.text.dark};
  margin-bottom: ${theme.common.spacing.lg};
  font-size: 1.2rem;
`;

// Transactions Table Components
const TransactionsTable = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 12px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
  overflow: hidden;
`;

const TransactionRow = styled.div`
  display: grid;
  grid-template-columns: 120px 150px minmax(200px, 1fr) minmax(200px, 1fr) 120px;
  gap: ${theme.common.spacing.md};
  padding: ${theme.common.spacing.md} ${theme.common.spacing.lg};
  border-bottom: 1px solid ${theme.colors.border.light};
  transition: all ${theme.common.transitions.fast};
  align-items: center;
  min-width: 1000px;
  background: ${props => props.isHeader ? theme.colors.background.muted : 'transparent'};
  
  &:hover {
    background: ${props => props.isHeader ? theme.colors.background.muted : theme.colors.background.muted};
    transform: ${props => props.isHeader ? 'none' : 'translateX(4px)'};
  }

  &:last-child {
    border-bottom: none;
    margin-top: ${theme.common.spacing.sm};
    background: ${theme.colors.background.muted};
    border-radius: 8px;
  }
`;

const CategoryLabel = styled.span`
  color: ${props => props.bold ? theme.colors.text.dark : theme.colors.text.primary};
  font-weight: ${props => props.bold ? '600' : 'normal'};
  font-family: ${theme.fonts.body};
  display: flex;
  align-items: center;
  font-size: ${props => props.bold ? '0.95rem' : '0.9rem'};

  
  /* Make the first column sticky if needed */
  ${props => props.isFirstColumn && `
    position: sticky;
    left: 0;
    background: ${props.isHeader ? theme.colors.background.muted : theme.colors.background.subtle};
    z-index: 2;
    padding-left: ${theme.common.spacing.lg};
  `}
`;

const AmountCell = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Amount = styled.span`
  color: ${props => props.negative ? 'var(--forest-loss)' : 'var(--forest-profit)'};
  font-family: ${theme.fonts.mono};
  font-size: 0.9rem;
  font-weight: ${props => props.bold ? '600' : 'normal'};
  white-space: nowrap;
`;

const CategoryIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 2px;
  background: ${props => props.color};
  margin-right: ${theme.common.spacing.sm};
`;

// Summary Table Components
const SummaryTableContainer = styled.div`
  margin: ${theme.common.spacing.xl} 0;
  background: ${theme.colors.background.subtle};
  border-radius: 12px;
  padding: ${theme.common.spacing.lg};
  box-shadow: ${theme.common.shadows.sm};
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  overflow-x: auto;
  margin: 0 -${theme.common.spacing.lg};
  padding: 0 ${theme.common.spacing.lg};
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

// Update SummaryTableRow to include a flex container for the category name and indicator
const SummaryTableRow = styled.div`
  display: grid;
  grid-template-columns: 200px minmax(120px, 1fr);
  padding: ${theme.common.spacing.md} ${theme.common.spacing.lg};
  border-bottom: 1px solid ${theme.colors.border.light};
  transition: all ${theme.common.transitions.fast};
  align-items: center;
  min-width: 400px;
  
  &:hover {
    background: ${props => props.isHeader ? 'transparent' : theme.colors.background.muted};
    transform: ${props => props.isHeader ? 'none' : 'translateX(4px)'};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const CategoryNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRow = styled(SummaryTableRow)`
  background: ${theme.colors.background.muted};
  border-radius: 8px;
  margin-bottom: ${theme.common.spacing.sm};
  font-weight: 600;
  position: sticky;
  left: 0;
`;

const FooterRow = styled(SummaryTableRow)`
  margin-top: ${theme.common.spacing.sm};
  background: ${theme.colors.background.muted};
  border-radius: 8px;
  font-weight: 600;
`;

const SummaryCell = styled.div`
  color: ${props => {
    if (props.isNegative) {
      return 'var(--forest-loss)';
    }
    // Add explicit condition for positive values
    if (props.isNumeric && !props.isNegative) {
      return 'var(--forest-profit)';
    }
    return 'var(--forest-dark)';
  }};
  font-family: ${props => props.isNumeric ? theme.fonts.mono : theme.fonts.body};
  font-size: 0.9rem;
  text-align: ${props => props.isNumeric ? 'right' : 'left'};
  padding-right: ${props => props.isNumeric ? theme.common.spacing.md : '0'};
  font-weight: ${props => props.isSubtotal || props.isHeader || props.isFooter ? '600' : 'normal'};
  
  /* Make the first column sticky */
  ${props => props.isFirstColumn && `
    position: sticky;
    left: 0;
    background: ${props.isHeader || props.isFooter ? theme.colors.background.muted :
      props.isSubtotal ? theme.colors.background.muted :
        theme.colors.background.subtle};
    z-index: 2;
    padding-left: ${theme.common.spacing.lg};
  `}
`;

const formatCurrency = (value) => {
  return `$${Math.abs(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

const FilterControls = styled.div`
  display: flex;
  gap: ${theme.common.spacing.md};
  margin-bottom: ${theme.common.spacing.lg};
  align-items: center;
  flex-wrap: wrap;
`;

const SearchInput = styled.input`
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 6px;
  font-family: ${theme.fonts.body};
  font-size: 0.9rem;
  width: 250px;
  background: ${theme.colors.background.muted};
  color: ${theme.colors.text.primary};
  transition: all ${theme.common.transitions.fast};

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
    background: ${theme.colors.background.subtle};
  }

  &::placeholder {
    color: ${theme.colors.text.muted};
  }
`;

const Select = styled.select`
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 6px;
  font-family: ${theme.fonts.body};
  font-size: 0.9rem;
  background: ${theme.colors.background.muted};
  color: ${theme.colors.text.primary};
  cursor: pointer;
  transition: all ${theme.common.transitions.fast};

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
    background: ${theme.colors.background.subtle};
  }
`;

const SortButton = styled.button`
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 6px;
  font-family: ${theme.fonts.body};
  font-size: 0.9rem;
  background: ${theme.colors.background.muted};
  color: ${theme.colors.text.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${theme.common.spacing.sm};
  transition: all ${theme.common.transitions.fast};

  &:hover {
    background: ${theme.colors.background.subtle};
    border-color: ${theme.colors.primary};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

// Add new styled components
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TitleSection = styled.div``;

const PrintButton = styled.button`
  background: ${theme.colors.primary};
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: ${theme.fonts.body};
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: ${theme.colors.primaryDark};
  }
`;

const DateFilterContainer = styled.div`
  display: flex;
  gap: ${theme.common.spacing.md};
  align-items: center;
  margin-bottom: ${theme.common.spacing.lg};
`;

const StyledSelect = styled.select`
  padding: ${theme.common.spacing.sm} ${theme.common.spacing.md};
  border: 1px solid ${theme.colors.border.light};
  border-radius: 6px;
  font-family: ${theme.fonts.body};
  font-size: 0.9rem;
  background: ${theme.colors.background.muted};
  color: ${theme.colors.text.primary};
  width: 130px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
    background: ${theme.colors.background.subtle};
  }
`;

// Add new styled component for the download link
const DownloadLink = styled.a`
  color: ${theme.colors.primary};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: ${theme.common.spacing.sm};
  font-size: 0.9rem;
  
  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

// Add download icon component
const DownloadIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 7v10m0 0l-4-4m4 4l4-4m-10 6h12" />
  </svg>
);

// Add new styled component for the toggle button
const ToggleButton = styled.button`
  background: none;
  border: none;
  color: ${theme.colors.text.primary};
  cursor: pointer;
  padding: ${theme.common.spacing.sm};
  display: flex;
  align-items: center;
  gap: ${theme.common.spacing.sm};
  transition: all ${theme.common.transitions.fast};

  &:hover {
    color: ${theme.colors.primary};
  }

  svg {
    width: 16px;
    height: 16px;
    transition: transform ${theme.common.transitions.fast};
    transform: rotate(${props => props.isOpen ? '90deg' : '0deg'});
  }
`;

// Replace the ChevronIcon component with a single arrow icon
const ChevronIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M9 18l6-6-6-6" />
  </svg>
);

// Update the categoryColors object with the new color scheme
const categoryColors = {
  'Harvesting': '#4CAF50',         // Green (income)
  'Road Construction': '#F44336',   // Red (expense)
  'Non Bookable Funding': '#2196F3', // Blue (different color)
  'Bookable Funding': '#4CAF50',   // Green (income)
  'Management Fee': '#F44336',     // Red (expense)
  'Silviculture': '#F44336',       // Red (expense)
  'Contracting': '#F44336',        // Red (expense)
  'Uncategorized': '#9E9E9E',      // Grey
  // Keep other categories with appropriate red/green based on whether they're income or expenses
  'Revenue': '#4CAF50',            // Green
  'Expenses': '#F44336',           // Red
  'Taxes': '#F44336',              // Red
  'Investments': '#2196F3',        // Blue
  'Insurance': '#F44336',          // Red
  'Maintenance': '#F44336',        // Red
  'Utilities': '#F44336',          // Red
  'Payroll': '#F44336',           // Red
  'Marketing': '#F44336',         // Red
  'Other': '#9E9E9E'             // Grey
};

const GrossProfitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.common.spacing.xs};
`;

const GrossProfitLabel = styled.div`
  color: ${theme.colors.text.muted};
  font-size: 0.9rem;
  font-family: ${theme.fonts.body};
`;

const AnnualReview = ({ selectedLandowners = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);

  // Add state for tracking open/closed invoice groups
  const [openInvoices, setOpenInvoices] = useState({});

  // Move filterTransactionsByDate into useMemo to avoid the dependency warning
  const filterTransactionsByDate = React.useCallback((transactions) => {
    return transactions.filter(transaction => {
      const transactionDate = new Date(transaction.date);
      return transactionDate.getFullYear() === selectedYear;
    });
  }, [selectedYear]);

  const filteredAndSortedTransactions = React.useMemo(() => {
    return filterTransactionsByDate(transactions)
      .filter(transaction => {
        const matchesSearch = transaction.description.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory === 'all' || transaction.category === selectedCategory;

        const matchesLandowner = selectedLandowners.length === 0 || (
          transaction.landowner && selectedLandowners.some(selected =>
            selected.value === transaction.landowner
          )
        );

        return matchesSearch && matchesCategory && matchesLandowner;
      })
      .sort((a, b) => {
        if (sortConfig.key === 'date') {
          const comparison = new Date(b.date) - new Date(a.date);
          return sortConfig.direction === 'desc' ? comparison : -comparison;
        } else if (sortConfig.key === 'amount') {
          const comparison = b.amount - a.amount;
          return sortConfig.direction === 'desc' ? comparison : -comparison;
        }
        return 0;
      });
  }, [
    filterTransactionsByDate,
    searchTerm,
    selectedCategory,
    sortConfig,
    transactions,
    selectedLandowners
  ]);

  const grossProfit = React.useMemo(() => {
    return filteredAndSortedTransactions.reduce((sum, transaction) => sum + transaction.amount, 0);
  }, [filteredAndSortedTransactions]);

  const filteredChartData = React.useMemo(() => {
    const categoryTotals = filteredAndSortedTransactions.reduce((acc, transaction) => {
      const category = transaction.category;
      acc[category] = (acc[category] || 0) + transaction.amount;
      return acc;
    }, {});

    // Calculate Gross Profit I & II using the same logic as categorySummary
    const grossProfitI = (
      (categoryTotals['Harvesting'] || 0) +
      (categoryTotals['Bookable Funding'] || 0) -
      (categoryTotals['Road Construction'] || 0) -
      (categoryTotals['Silviculture'] || 0)
    );

    // Calculate Gross Profit II
    const grossProfitII = grossProfitI - (categoryTotals['Management Fee'] || 0);

    let runningTotal = 0;
    const categoryData = Object.entries(categoryTotals)
      .map(([name, amount]) => {
        runningTotal += amount;
        return {
          name,
          amount,
          overall: runningTotal,
          color: categoryColors[name] || '#9E9E9E'
        };
      });

    // Add Gross Profit I & II with the correct values
    return [
      ...categoryData,
      {
        name: 'Gross Profit I',
        amount: grossProfitI,
        overall: grossProfitI,
        color: '#4CAF50'
      },
      {
        name: 'Gross Profit II',
        amount: grossProfitII,
        overall: grossProfitII,
        color: '#2196F3'
      }
    ];
  }, [filteredAndSortedTransactions]);

  const uniqueCategories = React.useMemo(() => {
    const categories = new Set(transactions.map(item => item.category));
    return ['all', ...Array.from(categories)];
  }, [transactions]);

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const baseId = 'appOzTtsysmPCXcnP';
      const tableId = 'tblQAuqWadLwtvY05';

      const data = await fetchAndParseAirtableRecords(baseId, tableId);

      const transformedData = data.map(record => ({
        category: record.Category?.[0] || 'Uncategorized',
        subcategory: record.Subcategory || 'Other',
        description: record.Comment || record['Transaction ID'] || 'No description',
        date: record.Date?.[0] || new Date().toISOString(),
        amount: record.Total || 0,
        color: record.color || 'var(--forest-dark)',
        landowner: Array.isArray(record.Landowner) ? record.Landowner[0] : record.Landowner,
        invoiceName: record.InvoiceName || 'Ungrouped',
        woodlotName: record.WoodlotName || '-',
        invoiceId: record['InvoiceName'] || '-',
        invoiceDocument: record.InvoiceDocument?.[0] || '-'
      }));

      setTransactions(transformedData);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Check permissions when component mounts
    const checkPermissions = () => {
      const userPermissions = localStorage.getItem('userPermissions');
      if (userPermissions) {
        try {
          setHasPermission(true);
        } catch (error) {
          setHasPermission(false);
        }
      } else {
        setHasPermission(false);
      }
    };
    checkPermissions();
  }, []);

  const SortIcon = ({ direction }) => (
    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      { direction === 'desc' ? (
        <path d="M19 14l-7 7-7-7M19 3l-7 7-7-7" />
      ) : (
        <path d="M19 21l-7-7-7 7M19 10l-7-7-7 7" />
      ) }
    </svg>
  );

  const handlePrint = () => {
    window.print();
  };

  const groupedTransactions = React.useMemo(() => {
    const groups = {};
    filteredAndSortedTransactions.forEach(transaction => {
      const groupKey = transaction.invoiceName;
      if (!groups[groupKey]) {
        groups[groupKey] = {
          transactions: [],
          total: 0
        };
      }
      groups[groupKey].transactions.push(transaction);
      groups[groupKey].total += transaction.amount;
    });
    return groups;
  }, [filteredAndSortedTransactions]);

  const categorySummary = React.useMemo(() => {
    const summary = {};

    // First pass to calculate category totals
    filteredAndSortedTransactions.forEach(transaction => {
      const category = transaction.category;
      const subcategory = transaction.subcategory || 'Other';

      if (!summary[category]) {
        summary[category] = {
          total: 0,
          subcategories: {}
        };
      }

      summary[category].total += transaction.amount;

      if (!summary[category].subcategories[subcategory]) {
        summary[category].subcategories[subcategory] = 0;
      }
      summary[category].subcategories[subcategory] += transaction.amount;
    });

    // Calculate Gross Profit I using the same formula
    const grossProfitI = (
      (summary['Harvesting']?.total || 0) +
      (summary['Bookable Funding']?.total || 0) -
      (summary['Road Construction']?.total || 0) -
      (summary['Silviculture']?.total || 0)
    );

    // Calculate Gross Profit II
    const grossProfitII = grossProfitI - (summary['Management Fee']?.total || 0);

    // Add Gross Profits to summary
    summary['Gross Profit I'] = {
      total: grossProfitI,
      subcategories: {}
    };

    summary['Gross Profit II'] = {
      total: grossProfitII,
      subcategories: {}
    };

    return summary;
  }, [filteredAndSortedTransactions]);

  // Add toggle handler
  const toggleInvoice = (invoiceName) => {
    setOpenInvoices(prev => ({
      ...prev,
      [invoiceName]: !prev[invoiceName]
    }));
  };

  // Generate array of years (e.g., last 10 years)
  const years = React.useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 10 }, (_, i) => currentYear - i);
  }, []);

  if (!hasPermission) {
    return (
      <DashboardContainer className="flex flex-col items-center justify-center">
        <Header>
          <Title>Access Denied</Title>
        </Header>
        <p>You do not have permission to view this content.</p>
      </DashboardContainer>
    );
  }

  if (isLoading) {
    return <LoadingOverlay message="Loading..." />;
  }

  if (error) {
    return <DashboardContainer>Error: { error }</DashboardContainer>;
  }

  return (
    <DashboardContainer>
      <Header>
        <HeaderContent>
          <TitleSection>
            <Title>Annual Review | { selectedLandowners[0]?.label || 'All Portfolios' }</Title>
            <GrossProfitContainer>
              <GrossProfit negative={ grossProfit < 0 }>
                CAD ${ grossProfit.toLocaleString() }
              </GrossProfit>
              <GrossProfitLabel>
                All-time total
              </GrossProfitLabel>
            </GrossProfitContainer>
          </TitleSection>
          <PrintButton onClick={ handlePrint }>
            <span>🖨️</span> Print
          </PrintButton>
        </HeaderContent>
      </Header>

      <DateFilterContainer>
        <span>Year:</span>
        <StyledSelect
          value={ selectedYear }
          onChange={ (e) => setSelectedYear(parseInt(e.target.value)) }
        >
          { years.map(year => (
            <option key={ year } value={ year }>{ year }</option>
          )) }
        </StyledSelect>
      </DateFilterContainer>

      <Overview>
        <SectionTitle>Overview</SectionTitle>
        <div style={ { width: '100%', height: 300 } }>
          <ResponsiveContainer>
            <BarChart
              data={ filteredChartData }
              margin={ { top: 40, right: 30, left: 20, bottom: 5 } }
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={ theme.colors.border.light }
                vertical={ false }
              />
              <XAxis
                dataKey="name"
                stroke={ theme.colors.text.primary }
                tick={ { fill: theme.colors.text.primary, fontSize: 12 } }
                tickLine={ { stroke: theme.colors.border.light } }
              />
              <YAxis
                stroke={ theme.colors.text.primary }
                tick={ { fill: theme.colors.text.primary, fontSize: 12 } }
                tickLine={ { stroke: theme.colors.border.light } }
                tickFormatter={ (value) => `$${value.toLocaleString()}` }
              />
              <Bar
                dataKey="amount"
                name="Transaction"
                radius={ [4, 4, 0, 0] }
              >
                { filteredChartData.map((entry, index) => (
                  <Cell key={ `cell-${index}` } fill={ entry.color } />
                )) }
                <LabelList
                  dataKey="amount"
                  position="top"
                  formatter={ (value) => `$${Math.abs(Math.round(value)).toLocaleString()}` }
                  style={ {
                    fill: theme.colors.text.primary,
                    fontSize: '12px',
                    fontFamily: theme.fonts.mono
                  } }
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Overview>

      <SummaryTableContainer>
        <SectionTitle>Summary</SectionTitle>
        <ScrollContainer>
          <HeaderRow>
            <SummaryCell isFirstColumn isHeader>Category</SummaryCell>
            <SummaryCell isNumeric isHeader>Total</SummaryCell>
          </HeaderRow>

          { Object.entries(categorySummary).map(([category, data]) => {
            // Skip rendering Gross Profits in the regular category list
            if (category === 'Gross Profit I' || category === 'Gross Profit II') {
              return null;
            }

            return (
              <SummaryTableRow key={ category }>
                <CategoryNameContainer>
                  <SummaryCell isFirstColumn bold>{ category }</SummaryCell>
                </CategoryNameContainer>
                <SummaryCell
                  isNumeric
                  bold
                  isNegative={ data.total < 0 }
                >
                  { formatCurrency(data.total) }
                </SummaryCell>
              </SummaryTableRow>
            );
          }) }

          {/* Add Gross Profit I */ }
          <FooterRow>
            <SummaryCell isFirstColumn isFooter>Gross Profit I</SummaryCell>
            <SummaryCell
              isNumeric
              isNegative={ categorySummary['Gross Profit I'].total < 0 }
              isFooter
            >
              { formatCurrency(categorySummary['Gross Profit I'].total) }
            </SummaryCell>
          </FooterRow>

          {/* Add Gross Profit II */ }
          <FooterRow>
            <SummaryCell isFirstColumn isFooter>Gross Profit II</SummaryCell>
            <SummaryCell
              isNumeric
              isNegative={ categorySummary['Gross Profit II'].total < 0 }
              isFooter
            >
              { formatCurrency(categorySummary['Gross Profit II'].total) }
            </SummaryCell>
          </FooterRow>
        </ScrollContainer>
      </SummaryTableContainer>

      <TransactionsTable>
        <SectionTitle>Detailed Transactions</SectionTitle>
        <ScrollContainer>
          <FilterControls>
            <SearchInput
              type="text"
              placeholder="Search descriptions..."
              value={ searchTerm }
              onChange={ (e) => setSearchTerm(e.target.value) }
            />
            <Select
              value={ selectedCategory }
              onChange={ (e) => setSelectedCategory(e.target.value) }
            >
              { uniqueCategories.map(category => (
                <option key={ category } value={ category }>
                  { category === 'all' ? 'All Categories' : category }
                </option>
              )) }
            </Select>
            <SortButton onClick={ () => handleSort('date') }>
              Date
              <SortIcon direction={ sortConfig.key === 'date' ? sortConfig.direction : 'desc' } />
            </SortButton>
            <SortButton onClick={ () => handleSort('amount') }>
              Amount
              <SortIcon direction={ sortConfig.key === 'amount' ? sortConfig.direction : 'desc' } />
            </SortButton>
          </FilterControls>

          { Object.entries(groupedTransactions).map(([invoiceName, group]) => (
            <React.Fragment key={ invoiceName }>
              {/* Invoice Group Header */ }
              <TransactionRow
                style={ {
                  background: theme.colors.background.muted,
                  marginTop: theme.common.spacing.md,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                } }
              >
                <div style={ { display: 'flex', alignItems: 'center', gap: theme.common.spacing.md } }>
                  <ToggleButton
                    onClick={ () => toggleInvoice(invoiceName) }
                    isOpen={ openInvoices[invoiceName] }
                  >
                    <ChevronIcon />
                  </ToggleButton>
                  <CategoryLabel bold>Invoice: { invoiceName }</CategoryLabel>
                </div>
                <AmountCell
                  style={ { display: 'flex', alignItems: 'center', gap: theme.common.spacing.md } }
                >
                  { group.transactions[0]?.invoiceDocument?.url ? (
                    <DownloadLink
                      href={ group.transactions[0].invoiceDocument.url }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DownloadIcon /> PDF
                    </DownloadLink>
                  ) : (
                    <DownloadLink
                      disabled={ true }
                      style={ { color: theme.colors.text.muted, cursor: 'not-allowed' } }
                      href="#"
                      onClick={ (e) => e.preventDefault() }
                    >
                      <DownloadIcon /> No PDF
                    </DownloadLink>
                  ) }
                  <Amount negative={ group.total < 0 } bold>
                    { group.total < 0 ? '-' : '' }CAD ${ Math.abs(group.total).toLocaleString() }
                  </Amount>
                </AmountCell>
              </TransactionRow>

              {/* Show transactions only if invoice is open */ }
              { openInvoices[invoiceName] && (
                <>
                  {/* Column Headers */ }
                  <TransactionRow isHeader>
                    <CategoryLabel bold>Date</CategoryLabel>
                    <CategoryLabel bold>Category</CategoryLabel>
                    <CategoryLabel bold>Description</CategoryLabel>
                    <CategoryLabel bold>Woodlot Name</CategoryLabel>
                    <AmountCell>
                      <CategoryLabel bold>Amount</CategoryLabel>
                    </AmountCell>
                  </TransactionRow>

                  {/* Transactions within group */ }
                  { group.transactions.map((transaction, index) => (
                    <TransactionRow key={ index }>
                      <CategoryLabel>
                        { new Date(transaction.date).toLocaleDateString(undefined, {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        }) }
                      </CategoryLabel>
                      <CategoryLabel>
                        <CategoryIndicator color={ transaction.color } />
                        { transaction.category }
                      </CategoryLabel>
                      <CategoryLabel>{ transaction.description }</CategoryLabel>
                      <CategoryLabel>{ transaction.woodlotName }</CategoryLabel>
                      <AmountCell>
                        <Amount negative={ transaction.amount < 0 }>
                          { transaction.amount < 0 ? '-' : '' }CAD ${ Math.abs(transaction.amount).toLocaleString() }
                        </Amount>
                      </AmountCell>
                    </TransactionRow>
                  )) }
                </>
              ) }
            </React.Fragment>
          )) }

          {/* Grand Total Row */ }
          <TransactionRow>
            <CategoryLabel bold>Total</CategoryLabel>
            <CategoryLabel></CategoryLabel>
            <CategoryLabel></CategoryLabel>
            <CategoryLabel></CategoryLabel>
            <AmountCell>
              <Amount negative={ grossProfit < 0 } bold>
                { grossProfit < 0 ? '-' : '' }CAD ${ Math.abs(grossProfit).toLocaleString() }
              </Amount>
            </AmountCell>
          </TransactionRow>
        </ScrollContainer>
      </TransactionsTable>
    </DashboardContainer>
  );
};

export default AnnualReview;