import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white/90 backdrop-blur-sm shadow-lg p-4 rounded-lg border border-gray-200">
        <p className="font-semibold text-gray-900 mb-2">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} className="text-sm" style={{ color: entry.color }}>
            {entry.name}: {formatCurrency(entry.value)}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const CashflowChart = React.memo(({ years, categories }) => {
  const { projectedReturns, operationalCosts, netCashflow, totalReturns, totalCosts, roi } = useMemo(() => {
    const calculateProjections = () => {
      const totalInvestment = Object.values(categories).reduce((sum, category) => {
        const budget = parseFloat(category.metrics.budget?.replace(/[^0-9.]/g, '') || 0);
        return sum + budget;
      }, 0);

      const projectedReturns = years.map((year, index) => {
        const baseReturnRate = 0.08;
        const growthIncrement = 0.01;
        const returnRate = baseReturnRate + (index * growthIncrement);
        return totalInvestment * (1 + returnRate);
      });

      const operationalCosts = years.map((year, index) => {
        const baseOperationalRate = 0.30;
        const annualInflation = 0.03;
        return totalInvestment * baseOperationalRate * Math.pow(1 + annualInflation, index);
      });

      const netCashflow = projectedReturns.map((returns, index) => 
        returns - operationalCosts[index]
      );

      const totalReturns = projectedReturns.reduce((a, b) => a + b, 0);
      const totalCosts = operationalCosts.reduce((a, b) => a + b, 0);
      const netReturn = totalReturns - totalCosts - totalInvestment;
      const roi = ((netReturn / totalInvestment) * 100).toFixed(1);

      return { 
        projectedReturns, 
        operationalCosts, 
        netCashflow, 
        totalReturns, 
        totalCosts, 
        roi 
      };
    };

    return calculateProjections();
  }, [years, categories]);

  const data = useMemo(() => 
    years.map((year, index) => ({
      year,
      'Projected Returns': projectedReturns[index],
      'Operational Costs': operationalCosts[index],
      'Net Cashflow': netCashflow[index]
    }))
  , [years, projectedReturns, operationalCosts, netCashflow]);

  return (
    <div className="w-full bg-white rounded-xl shadow-sm p-6">
      <div className="mb-6">
        <h3 className="text-xl font-semibold text-gray-900">Financial Projections</h3>
        <p className="text-sm text-gray-600">5-year forecast with operational costs and returns</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-green-50 rounded-lg p-4">
          <p className="text-sm text-green-600 mb-1">Total Projected Returns</p>
          <p className="text-lg font-semibold text-green-700">{formatCurrency(totalReturns)}</p>
        </div>
        <div className="bg-red-50 rounded-lg p-4">
          <p className="text-sm text-red-600 mb-1">Total Operational Costs</p>
          <p className="text-lg font-semibold text-red-700">{formatCurrency(totalCosts)}</p>
        </div>
        <div className="bg-blue-50 rounded-lg p-4">
          <p className="text-sm text-blue-600 mb-1">Expected ROI</p>
          <p className="text-lg font-semibold text-blue-700">{roi}%</p>
        </div>
      </div>

      <div className="w-full h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis tickFormatter={formatCurrency} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="Projected Returns" 
              stroke="#22c55e" 
              strokeWidth={2}
              dot={false}
            />
            <Line 
              type="monotone" 
              dataKey="Operational Costs" 
              stroke="#ef4444" 
              strokeWidth={2}
              dot={false}
            />
            <Line 
              type="monotone" 
              dataKey="Net Cashflow" 
              stroke="#3b82f6" 
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});

CashflowChart.displayName = 'CashflowChart';

export default CashflowChart;
