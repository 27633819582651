import React from 'react';
import ArcGISMap from '../../../MapComponents/ArcGISMap';

const MapView = () => {
  // Using a forestry-focused webmap - this is a sample ID for a topographic map
  const webmapId = "d5e02a0c1f2b4ec399823fdd3c2fdebd";
  // Setting extent to a forested area (example coordinates)
  const extent = "-63.65,44.63,-63.50,44.72"; // Example coordinates for Halifax Nova Scotia

  return (
    <div className="my-8 bg-gray-50 rounded-xl p-6 shadow-sm h-[400px] overflow-hidden">
      <h2 className="text-xl font-semibold text-gray-900 mb-6 px-6">Forest Area Overview</h2>
      <div className="w-full h-[calc(100%-60px)] rounded-lg overflow-hidden bg-gray-100">
        <ArcGISMap 
          webmapId={webmapId}
          extent={extent}
          height="100%"
        />
      </div>
    </div>
  );
};

export default MapView;
