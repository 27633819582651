import React, { useEffect, useRef, useState } from 'react';
import { FaUser, FaRegCalendarAlt } from 'react-icons/fa';
import MarkdownRenderer from '../../../components/MarkdownRenderer';
import { theme } from '../../../styles';

const getCategoryColor = (type) => {
  const colors = {
    'Ground Report': 'bg-emerald-100 text-emerald-800',
    'Annual Review': 'bg-blue-100 text-blue-800',
    'Quarterly Update': 'bg-purple-100 text-purple-800',
    'Site Visit': 'bg-amber-100 text-amber-800'
  };
  return colors[type] || 'bg-gray-100 text-gray-800';
};

const TimelineItem = ({ index, item, onInView }) => {
  const [imageAspectRatio, setImageAspectRatio] = useState('landscape');
  const itemRef = useRef(null);

  /**
   * Handles the image load event and sets the aspect ratio state
   * Calculates whether an image is landscape or portrait based on its natural dimensions
   * @param {Event} event - The image load event object
   */
  const handleImageLoad = (event) => {
    const img = event.target;
    const ratio = img.naturalWidth / img.naturalHeight;
    setImageAspectRatio(ratio >= 1 ? 'landscape' : 'portrait');
  };

  useEffect(() => {
    const currentRef = itemRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onInView();
        }
      },
      {
        threshold: [0.1],
        rootMargin: '0px'
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [onInView]);

  if (!item) {
    return null;
  }

  return (
    <div 
      ref={itemRef}
      className={`relative flex transition-default ${
        index % 2 === 0 
          ? 'md:justify-end' 
          : 'md:justify-start'
      }`}
      style={{ fontFamily: theme.fonts.body }}
    >
      <div className={`w-full md:w-[calc(50%-3rem)] relative ${
        index % 2 === 0 
          ? 'md:pr-8' 
          : 'md:pl-8'
      }`}>
        <div className="glassmorphism rounded-lg p-6 transition-slow hover:bg-opacity-80">
          <div className="flex items-center gap-3 mb-2">
            <h3 className="text-base font-semibold" style={{ 
              fontFamily: theme.fonts.heading,
              color: theme.colors.text.dark 
            }}>{item?.title || 'Untitled'}</h3>
            <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${getCategoryColor(item.type)}`}>
              {item.type}
            </span>
          </div>
          
          <div className="flex items-center gap-3 mb-4 text-xs" style={{ color: theme.colors.text.muted }}>
            <div className="flex items-center gap-1.5 bg-background-subtle px-2.5 py-0.5 rounded-full">
              <FaRegCalendarAlt className="w-3 h-3" style={{ color: theme.colors.text.muted }} />
              <span>{item.date}</span>
            </div>
            <div className="flex items-center gap-1.5 bg-background-subtle px-2.5 py-0.5 rounded-full">
              <FaUser className="w-3 h-3" style={{ color: theme.colors.text.muted }} />
              <span>{item.createdBy?.name}</span>
            </div>
          </div>

          {item.image && (
            <div className={`mb-4 ${
              imageAspectRatio === 'portrait' 
                ? 'max-h-full' 
                : 'h-auto'
            }`}>
              <img
                src={item.image}
                alt={item.title}
                className={`w-full rounded-lg shadow-md ${
                  imageAspectRatio === 'portrait'
                    ? 'h-auto max-h-full object-contain'
                    : 'h-auto object-cover'
                }`}
                onLoad={handleImageLoad}
              />
            </div>
          )}

          <div style={{ color: theme.colors.text.primary }}>
            <MarkdownRenderer content={item.text} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineItem; 