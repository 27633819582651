import React, { useState, useEffect } from 'react';
import { fetchAndParseDueDiligenceData } from '../services/notionService';
import OpportunityCard from '../components/OpportunityCard';

const NOTION_DATABASE_ID = '550971b2b3354d3da9d59a9daf9b5a96';

/**
 * DueDiligenceGallery component
 * Displays raw due diligence data fetched from Notion
 */
const DueDiligenceGallery = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    // Check permissions when component mounts
    const checkPermissions = () => {
      const userPermissions = localStorage.getItem('userPermissions');
      if (userPermissions) {
        try {
          setHasPermission(true);
        } catch (error) {
          setHasPermission(false);
        }
      } else {
        setHasPermission(false);
      }
    };
    checkPermissions();
  }, []);

  useEffect(() => {
    const fetchDueDiligences = async () => {
      try {
        console.log('Fetching due diligences from Notion API...');
        const parsedData = await fetchAndParseDueDiligenceData(NOTION_DATABASE_ID);

        const publicData = parsedData.filter(item => item.viewability === "Public");
        const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '{}');

        if (userPermissions.accessLevel !== 'Selected Access') {
          setParsedData(publicData);
          setLoading(false);
          return;
        }

        const matchData = publicData.filter(record => {
          const allowedDueDiligences = Array.isArray(userPermissions.allowedDueDiligences)
            ? userPermissions.allowedDueDiligences
            : [userPermissions.allowedDueDiligences];

          return allowedDueDiligences.some(portfolio =>
            record.name?.toLowerCase() === portfolio?.toLowerCase()
          );
        });

        setParsedData(matchData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching due diligences:', err);
        setError(`Failed to fetch due diligences: ${err.message}`);
        setLoading(false);
      }
    };

    fetchDueDiligences();
  }, []);

  if (!hasPermission) {
    return (
      <div className="p-8 max-w-7xl mx-auto min-h-[50vh] flex flex-col items-center justify-center">
        <div className="mb-8 pb-4 border-b border-gray-200">
          <h1 className="text-3xl text-red-600 mb-4">Access Denied</h1>
        </div>
        <p className="text-lg text-gray-700 text-center">
          You do not have permission to view this content.
        </p>
      </div>
    );
  }

  if (loading) return <div className="text-center mt-8">Loading due diligences...</div>;
  if (error) return <div className="text-center mt-8 text-red-600">Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Opportunities Gallery</h1>

      {/* Parsed Data Section */}
      <div className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {parsedData.map(item => (
            <OpportunityCard
              key={item.id}
              {...item}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DueDiligenceGallery;