import React, { useState } from 'react';

const MetricCard = ({ category, color = '#4CAF50', metrics }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <div className="space-y-2">
          {value.map((item, idx) => (
            <div key={idx} className="flex justify-between items-center">
              {typeof item === 'object' ? (
                <>
                  <span className="text-gray-600">{item.name || item.type}</span>
                  {((item.amount || item.length) || item.status) && (
                    <span className="px-2 py-1 rounded text-sm" style={{ backgroundColor: `${color}20`, color }}>
                      {item.amount || item.length || item.status}
                    </span>
                  )}
                </>
              ) : (
                <>
                  <span className="text-gray-600">{item}</span>
                  <span className="px-2 py-1 rounded text-sm" style={{ backgroundColor: `${color}20`, color }}>
                    {item}
                  </span>
                </>
              )}
            </div>
          ))}
        </div>
      );
    }
    if (typeof value === 'object') {
      return (
        <div className="space-y-2">
          {Object.entries(value).map(([key, val]) => (
            <div key={key} className="flex justify-between items-center">
              <span className="text-gray-600">{key}</span>
              <span className="text-gray-900">{val}</span>
            </div>
          ))}
        </div>
      );
    }
    return value;
  };

  const mainMetric = Object.entries(metrics)[0];
  const trend = metrics.trend;
  const isPositiveTrend = trend?.startsWith('+');

  return (
    <div 
      className="bg-white border border-gray-200 rounded-2xl p-6 w-full min-w-[280px] h-full flex flex-col relative transition-all duration-200 hover:shadow-lg hover:-translate-y-1"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex justify-between items-center">
        <span className="text-sm font-semibold" style={{ color }}>
          {category}
        </span>
        {trend && (
          <span className={`text-sm font-semibold px-3 py-1 rounded-md ${
            isPositiveTrend ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
          }`}>
            {trend}
          </span>
        )}
      </div>

      <div className="my-2">
        <div className="text-2xl font-bold text-gray-900 tracking-tight">
          {mainMetric && renderValue(mainMetric[1])}
        </div>
      </div>

      <div className={`transition-all duration-300 overflow-hidden ${
        isExpanded ? 'opacity-100 max-h-[1000px]' : 'opacity-0 max-h-0'
      }`}>
        {Object.entries(metrics).slice(1).map(([key, value]) => (
          <div key={key} className="mt-4">
            <h4 className="text-sm font-medium text-gray-500 mb-2">{key}</h4>
            {renderValue(value)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MetricCard;
