import React from 'react';
import { years, categories } from './data/categories.js';
import MetricCard from './components/MetricCard';
import CashflowChart from './components/CashflowChart';
import BudgetDistribution from './components/BudgetDistribution';
import Timeline from './components/Timeline';
import SummaryTable from './components/SummaryTable';
import MapView from './components/MapView';

const ManagementPlan = () => {
  const metrics = Object.entries(categories).map(([key, category]) => ({
    category: category.label,
    color: category.color,
    metrics: category.metrics
  }));

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="p-8 pt-24 bg-transparent w-full max-w-7xl mx-auto">
      <div className="flex justify-between items-start mb-8 pb-4 border-b border-gray-200">
        <div className="flex-1">
          <h1 className="text-2xl font-semibold text-gray-900 mb-2">
            Forest Management Plan | Test Forest Holding
          </h1>
          <p className="text-gray-600">
            Comprehensive overview of activities, investments, and projected returns (2024-2028)
          </p>
        </div>
        <button 
          onClick={handlePrint}
          className="bg-green-600 text-white px-4 py-2 rounded flex items-center gap-2 hover:bg-green-700 transition-colors duration-200"
        >
          <span>🖨️</span> Print
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {metrics.map((metric, index) => (
          <MetricCard
            key={index}
            category={metric.category}
            color={metric.color}
            metrics={metric.metrics}
          />
        ))}
      </div>

      <div className="flex flex-col lg:flex-row gap-8 mb-8">
        <div className="w-full lg:w-2/3">
          <CashflowChart years={years} categories={categories} />
        </div>
        <div className="w-full lg:w-1/3">
          <BudgetDistribution categories={categories} />
        </div>
      </div>

      <MapView />

      <SummaryTable />

      <Timeline years={years} categories={categories} />
    </div>
  );
};

export default ManagementPlan;
